import React from "react";
import PropTypes from "prop-types";
import { useHistory } from "react-router-dom";

import logoImg from "../../images/TELUS-logo.gif";

const Logo = ({ height }) => {
  let history = useHistory();
  return <img height={height} src={logoImg} alt={"LOGO_ALT"} style={{ cursor: 'pointer' }} onClick={() => history.push("/")} />;
};

Logo.propTypes = {
  height: PropTypes.string,
};

Logo.defaultProps = {
  height: "auto",
};

export default Logo;
