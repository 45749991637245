function getAddressObject(addressComponents) {
  const ShouldBeComponent = {
    streetNumber: ["street_number"],
    postalCode: ["postal_code"],
    streetName: ["street_address", "route"],
    province: [
      "administrative_area_level_1",
      "administrative_area_level_2",
      "administrative_area_level_3",
      "administrative_area_level_4",
      "administrative_area_level_5",
    ],
    city: [
      "locality",
      "sublocality",
      "sublocality_level_1",
      "sublocality_level_2",
      "sublocality_level_3",
      "sublocality_level_4",
    ],
    country: ["country"],
  };

  const address = {
    streetNumber: "",
    postalCode: "",
    streetName: "",
    province: "",
    city: "",
    country: "",
  };
  addressComponents.forEach((component) => {
    // eslint-disable-next-line no-restricted-syntax
    for (const shouldBe in ShouldBeComponent) {
      if (ShouldBeComponent[shouldBe].indexOf(component.types[0]) !== -1) {
        if (shouldBe === "country") {
          address[shouldBe] = component.short_name;
        } else {
          address[shouldBe] = component.long_name;
        }
      }
    }
  });
  return address;
}

export default getAddressObject;
