export const dataKeys = {
  ADMIN: "adminData",
  THATSME: "thatsMe",
};

export const inputTypes = {
  mask: "mask",
  text: "text",
  number: "number",
};

export const fieldNames = {
  firstName: "firstName",
  lastName: "lastName",
  legalBusinessName: "legalBusinessName",
  email: "email",
  mobileNumber: "mobileNumber",
  title: "title",
  plan: "plan",
  term: "term",
  offer: "offer",
  eligibleEmployees: "eligibleEmployees",
  province: "province",
  preferredLanguage: "preferredLanguage",
};

export const feedbackTypes = {
  SUCCESS: "success",
  ERROR: "error",
};

export const languageTypes = {
  en: "en",
  fr: "fr",
};
