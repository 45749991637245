import React from "react";
import { withRouter, Route } from "react-router-dom";

import Header from "../Header";
import LandingPage from "../../Pages/LandingPage";
import SuccessScreen from "../../Pages/successScreen";

function App() {
  return (
    <>
      <Header />
      <Route exact path="/" component={LandingPage} />
      <Route path="/success" component={SuccessScreen} />
    </>
  );
}

export default withRouter(App);
