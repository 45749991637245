import * as Yup from "yup";

const phoneRegExp = /^(\d)(?!\1+$)\d{9}$/;

export const fwaSchema = (t) => {
	return Yup.object().shape({
		isSmartHubReceived: Yup.string().required(t(`Field Required`)),
		isChecklistCompleted: Yup.string().required(t(`Field Required`)),
		businessName: Yup.string().required(t(`Business Name Required`)),
		dealerEmail: Yup.string().email(t("Email Invalid")),
		streetNumber: Yup.string().required("Street Number Required"),
		streetName: Yup.string().required("Street Name Required"),
		unitNumber: Yup.string().required("Unit Number Required"),
		city: Yup.string().required("City Required"),
		province: Yup.string()
			.required(t("Province Required"))
			.test(
				"is-within-province",
				"Address should be in Alberta or British Columbia provinces",
				function (value) {
					if (!value) return false;
					return ["alberta", "british columbia"].includes(value.toLowerCase());
				}
			),
		postalCode: Yup.string().required("Postal Code Required"),
		email: Yup.string().email(t("Email Invalid")).required(t("Email Required")),
		accountNumber: Yup.string()
			.required("Account Number Required")
			.min(8, "Account Number must be 8 digits")
			.max(8, "Account Number no more than 8 digits"),
		subscriberNumber: Yup.string()
			.required(t("Subscriber Number Required"))
			.min(14, "Subscriber Number must be 10 digits")
			.max(14, "Subscriber Number no more than 10 digits")
			.test("is-subscriber-number", t("Subscriber Number Invalid"), (value) => {
				const number = value && value.replace(/[-() _]/g, "");
				return phoneRegExp.test(number);
			}),
		purchaseDeviceLocation: Yup.string().required(
			t("Purchase Device Location Required")
		),
		orderNumber: Yup.string(),
		onsiteContactName: Yup.string().required(t("Contact Name Required")),
		onsiteContactNumber: Yup.string()
			.required(t("Contact Number Required"))
			.min(14, "Contact Number must be 10 digits")
			.max(14, "Contact Number no more than 10 digits")
			.test("is-contact-number", t("Contact Number Invalid"), (value) => {
				const number = value && value.replace(/[-() _]/g, "");
				return phoneRegExp.test(number);
			}),
		businessHours: Yup.string().required(t("Business Hours Required")),
		buildingType: Yup.string().required(t("Building Type Required")),
		buildingPermission: Yup.string().required(
			t("Building Permission Required")
		),
		appointmentDate: Yup.string().required(t("Appointment Date Required")),
		preferredDate1: Yup.string().when("appointmentDate", {
			is: "Preferred Dates",
			then: Yup.string().required(t("Date Required"))
		}),
		preferredDate2: Yup.string().when("appointmentDate", {
			is: "Preferred Dates",
			then: Yup.string().required(t("Date Required"))
		}),
		additionalInfo: Yup.string()
	});
};
