import React from 'react';
import FlexGrid from '@tds/core-flex-grid';
import Box from '@tds/core-box';
import PropTypes from 'prop-types';

const Container = ({ children }) => {
  return (
    <Box vertical={5}>
      <FlexGrid limitWidth>{children}</FlexGrid>
    </Box>
  );
};

Container.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

Container.defaultProps = {
  children: null,
};

export default Container;
