import React, { useEffect, useState, useRef } from "react";

import PropTypes from "prop-types";
import Input from "@tds/core-input";
import Box from "@tds/core-box";
import Notification from "@tds/core-notification";
import Text from "@tds/core-text";
import Tooltip from "@tds/core-tooltip";
import { useTranslation } from "react-i18next";

import getAddressObject from "../../utils/googleAddress";
import {
	provinceAdminiterativeLevel,
	storageItemProvince
} from "../../utils/constants";

const AddressField = ({
	touched,
	errors,
	isSameAs,
	value,
	hint,
	disabled,
	removeLocalStorageOnChange,
	values,
	setValues
}) => {
	const [query, setQuery] = useState(null);
	const autoCompleteRef = useRef(null);
	const [t] = useTranslation();
	let autoComplete;

	const handlePlaceSelect = (updateQuery) => {
		const addressObject = autoComplete.getPlace();
		const { address_components } = addressObject || {};

		let province = null;
		let locality = null;
		if (address_components) {
			addressObject.address_components.forEach((component) => {
				if (component.types.includes(provinceAdminiterativeLevel)) {
					province = component.short_name;
				}
				if (component.types.includes("locality")) {
					locality = component.short_name;
				}
			});
		}
		if (province) {
			window.localStorage.setItem(storageItemProvince, province);
		}
		if (locality) {
			window.localStorage.setItem("locality", locality);
		} else {
			window.localStorage.removeItem("locality");
		}
		const queryRes = getAddressObject(addressObject.address_components);
		updateQuery(queryRes);
	};

	function handleScriptLoad(updateQuery) {
		autoComplete = new window.google.maps.places.Autocomplete(
			autoCompleteRef.current,
			{ types: ["geocode"], componentRestrictions: { country: "ca" } }
		);
		autoComplete.setFields(["address_components", "formatted_address"]);
		autoComplete.addListener("place_changed", () =>
			handlePlaceSelect(updateQuery)
		);
	}

	useEffect(() => {
		handleScriptLoad(setQuery);
	}, []);

	useEffect(() => {
		if (query !== null) {
			/**
			 * Setting individual address values
			 * ie. street number, street name, city, province, & postal code
			 */
			if (typeof query === "object")
				setValues({
					...values,
					...query
				});
		}
	}, [query]);

	let currentValue =
		(typeof query === "string" && query) || values.streetNumber || "";
	if (isSameAs) {
		currentValue = value;
	}

	return (
		<>
			<div className='business-address-fields-container'>
				<div className='business-address-heading-container'>
					<h5 className='business-address-heading'>
						{t("BUSINESS_ADDRESS_HEADING")}
					</h5>
					<Tooltip id='tooltipbar' copy='en'>
						Hint: Service address device to be installed at
					</Tooltip>
				</div>
				<div style={{ marginBottom: "1rem" }}>
					<Notification copy='en'>
						<Text small>Note: </Text>Address can only be selected from Alberta
						Or British Colombia Province
					</Notification>
				</div>
				<Box tag='fieldset' between={3}>
					<Box tag='fieldset' between={2}>
						<legend>
							<Text bold size='medium'>
								{t("STREET_NUMBER")}
							</Text>
						</legend>
						<Input
							ref={autoCompleteRef}
							onChange={(e) => {
								removeLocalStorageOnChange &&
									window.localStorage.removeItem("province");
								setValues({
									...values,
									streetNumber: ""
								});
								setQuery(e.target.value);
							}}
							id='streetNumber'
							label={""}
							name='streetNumber'
							value={currentValue || values.streetNumber || ""}
							error={touched.streetNumber && errors.streetNumber}
							feedback={touched.streetNumber && errors.streetNumber && "error"}
							hint={hint || ""}
							placeholder='Enter a location'
							disabled={disabled}
							className='frg-input-field color-light'
							style={{ width: "100%" }}
							onKeyPress={(e) => {
								e.key === "Enter" && e.preventDefault();
							}}
						/>
					</Box>
					<Box tag='fieldset' between={2}>
						<legend>
							<Text bold size='medium'>
								{t("STREET_NAME")}
							</Text>
						</legend>
						<Input
							onChange={(e) => {
								setValues({
									...values,
									streetName: e.target.value
								});
							}}
							id='streetName'
							label={""}
							name='streetName'
							value={values.streetName}
							placeholder=''
							error={touched.streetName && errors.streetName}
							feedback={touched.streetName && errors.streetName && "error"}
							className='frg-input-field color-light'
							style={{ width: "100%" }}
						/>
					</Box>
					<Box tag='fieldset' between={2}>
						<legend>
							<Text bold size='medium'>
								{t("UNIT_NUMBER")}
							</Text>
						</legend>
						<Input
							onChange={(e) => {
								setValues({
									...values,
									unitNumber: e.target.value
								});
							}}
							id='unitNumber'
							label={""}
							name='unitNumber'
							value={values.unitNumber}
							placeholder=''
							error={touched.unitNumber && errors.unitNumber}
							feedback={touched.unitNumber && errors.unitNumber && "error"}
							className='frg-input-field color-light'
							style={{ width: "100%" }}
						/>
					</Box>
					<Box tag='fieldset' between={2}>
						<legend>
							<Text bold size='medium'>
								{t("CITY")}
							</Text>
						</legend>
						<Input
							onChange={(e) => {
								setValues({
									...values,
									city: e.target.value
								});
							}}
							id='city'
							label={""}
							name='city'
							placeholder=''
							value={values.city}
							error={touched.city && errors.city}
							feedback={touched.city && errors.city && "error"}
							className='frg-input-field color-light'
							style={{ width: "100%" }}
						/>
					</Box>
					<Box tag='fieldset' between={2}>
						<legend>
							<Text bold size='medium'>
								{t("PROVINCE")}
							</Text>
						</legend>
						<Input
							onChange={(e) => {
								setValues({
									...values,
									province: e.target.value
								});
							}}
							id='province'
							label={""}
							name='province'
							placeholder=''
							value={values.province}
							error={touched.province && errors.province}
							feedback={touched.province && errors.province && "error"}
							className='frg-input-field color-light'
							style={{ width: "100%" }}
						/>
					</Box>
					<Box tag='fieldset' between={2}>
						<legend>
							<Text bold size='medium'>
								{t("POSTAL_CODE")}
							</Text>
						</legend>
						<Input
							onChange={(e) => {
								setValues({
									...values,
									postalCode: e.target.value
								});
							}}
							id='postalCode'
							label={""}
							name='postalCode'
							placeholder=''
							value={values.postalCode}
							error={touched.postalCode && errors.postalCode}
							feedback={touched.postalCode && errors.postalCode && "error"}
							className='frg-input-field color-light'
							style={{ width: "100%" }}
						/>
					</Box>
				</Box>
			</div>
		</>
	);
};

AddressField.propTypes = {
	name: PropTypes.string,
	label: PropTypes.string,
	touched: PropTypes.objectOf(PropTypes.any).isRequired,
	errors: PropTypes.objectOf(PropTypes.any).isRequired,
	min: PropTypes.func,
	placeholder: PropTypes.string,
	readonly: PropTypes.bool,
	disabled: PropTypes.bool,
	isSameAs: PropTypes.bool,
	hint: PropTypes.string,
	values: PropTypes.objectOf(PropTypes.any).isRequired,
	setValues: PropTypes.func.isRequired
};

export default AddressField;
