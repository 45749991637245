import React from 'react';
import Box from '@tds/core-box';
import PropTypes from 'prop-types';
import FlexGrid from '@tds/core-flex-grid';

const FormCol = ({ children, md, xs }) => {
  return (
    <FlexGrid.Col md={md} xs={xs}>
      <Box vertical={3} horizontal={3}>
        {children}
      </Box>
    </FlexGrid.Col>
  );
};

FormCol.propTypes = {
  md: PropTypes.number,
  xs: PropTypes.number,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
};

FormCol.defaultProps = {
  md: 12,
  xs: 12,
  children: null,
};

export default FormCol;
